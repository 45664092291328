import * as React from "react"
import Page from "../components/page.js"

import '../components/css/contact.scss'

var HOSTNAME = `${process.env.API_URL}`;

const Contact = () => {
  async function sendContact() {
    var first_name = document.getElementById('firstname').value;
    var last_name = document.getElementById('lastname').value;
    var email = document.getElementById('email').value;
    var company = document.getElementById('company').value;
    var phone = document.getElementById('phone').value;
    var jobTitle = document.getElementById('job-title').value;
    var details = document.getElementById('details').value;

    var data = {
      'first_name': first_name,
      'last_name': last_name,
      'email': email,
      'phone_number': phone,
      'company': company,
      'job_title': jobTitle,
      'message': details
    }

    var request = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    };

    const response = await fetch(HOSTNAME + "contactForm", request);
    var responseJson = response.json();
    responseJson.then(function(result) {
      if (result["status"] === 200) {
        window.location.replace("/contact")
      } else {
        alert("Could not submit form!")
      }
    })
  }

  return (
    <>
      <Page>
        <div className="contact-container">
          <div className="contact-content-wrapper">
            <div className="contact-content">
              <h2>Contact Our Team</h2>
              <h4>We'd love to discuss how we can work together.</h4>
              <div className="contact-form">
                <div className="contact-form-content">
                  <input type="text" name="first-name" id="firstname" placeholder="First Name" required />
                  <input type="text" name="last-name" id="lastname" placeholder="Last Name" required />
                </div>
                <div className="contact-form-content">
                  <input type="text" name="email" id="email" placeholder="Email" required />
                </div>
                <div className="contact-form-content">
                  <input type="text" name="company" id="company" placeholder="Company" required />
                  <input type="text" name="phone" id="phone" placeholder="Phone Number" required />
                </div>
                <div className="contact-form-content">
                  <input type="text" name="jobtitle" id="job-title" placeholder="Job Title" required />
                </div>
                <div className="contact-form-content">
                  <textarea name="details" id="details" placeholder="Details on your project"></textarea>
                </div>
                <div className="contact-form-content">
                  <input type="submit" name="submit" id="submit" value="Contact Sales" onClick={() => sendContact()}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  )
}

export default Contact
